<template>
  <div class="ecommerce-application">
    <div class="list-view">
      <b-card class="ecommerce-card" no-body>
        <div class="item-img">
          <b-link>
            <slot name="img-logo" />
          </b-link>
        </div>
        <b-card-body style="padding: 3.5rem 2rem !important;">
          <b-row>
            <b-col cols="12" sm="3">
              <div class="data-ticket">
                <h3 class="font-lg">Salida</h3>
                <h2 class="font-2xl">
                  {{ getFormattedTime(travel['departureDate']) }}
                </h2>
                <h5 class="font-lg">{{ travel['originTerminalShortName'] }}</h5>
              </div>
            </b-col>
            <b-col cols="12" sm="3" align-self="center" class="py-2">
              <span class="duration-travel font-xs">
                {{ setDateAprox(travel['departureDate'], travel['arrivalDate']) }}
              </span>
            </b-col>
            <b-col cols="12" sm="3">
              <div class="data-ticket">
                <h3 class="font-lg">LLegada</h3>
                <h2 class="font-2xl">
                  {{ getFormattedTime(travel['arrivalDate']) }}
                </h2>
                <h5 class="font-lg">{{ travel['destinationTerminalShortName'] }}</h5>
              </div>
            </b-col>
            <b-col cols="12" sm="3">
              <div class="data-ticket">
                <h6 class="font-sm">Tipo de Servicio</h6>
                <b-badge v-if="travel['typeIntegration'] == 'sisorg'" variant="primary" pill class="badge-round" style="font-size: 9px;">
                  {{ setTypeBus(travel['tariff']) }}
                </b-badge>
                <b-img v-if="travel['typeIntegration'] == 'brasilia'"
                  width="100"
                  :src="setImg(travel)"
                />
              </div>
            </b-col>
            <b-col v-if="travel['typeIntegration'] == 'brasilia'" cols="12" class="mt-1">
              <b-img
                width="250"
                :src="setImgItem(travel)"
              />
            </b-col>
            <b-col cols="12">
              <vue-policies :policies="travel.policy" :id-viaje="travel.tripId" />
            </b-col>
          </b-row>
        </b-card-body>

        <div class="item-options text-center">
          <div class="item-wrapper">
            <div class="item-cost">
              <div class="data-ticket">
                <h3 class="font-lg">Desde</h3>
                <h2>${{ setPricing(travel['tariff']) }}</h2>
                <h5 class="font-base">*Aplican costos adicionales </h5>
              </div>
            </div>
          </div>
          <b-button variant="primary" class="btn-cart move-cart" @click="setButacas(travel)">
            <span class="text-nowrap">Seleccionar sillas</span>
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BLink, BRow, BCol, BButton, BBadge, BImg,
} from 'bootstrap-vue'
import Policies from '@/components/SearchTicket/Ticket/policies.vue'
import moment from 'moment'
import 'moment-precise-range-plugin'
import 'moment/locale/es'

export default {
  components: {
    BCard,
    BLink,
    BRow,
    BCol,
    BImg,
    BCardBody,
    BBadge,
    BButton,
    'vue-policies': Policies,
  },
  // eslint-disable-next-line
  props: ['travel'],
  methods: {
    getFormattedTime(dateString) {
      // Establece moment para el formato adecuado y en español
      moment.locale('es');
      const date = moment(dateString, 'DD/MM/YYYY HH:mm:ss');

      // Devuelve solo la hora en formato de 12 horas con AM/PM
      return date.format('h:mm A'); // e.g., "3:00 PM"
    },
    setDateAprox(start, end) {
      const endTime = moment(end, 'DD-MM-YYYY H:mm:ss')
      const startTime = moment(start, 'DD-MM-YYYY H:mm:ss').preciseDiff(endTime, true)
      return `${startTime.hours}H (Aprox)`
    },
    setButacas(travel) {
      this.$emit('travel-butacas', travel)
    },
    setPricing(value) {
      const travels = Array.isArray(value) ? value : [value]
      return this.formatPrice(parseFloat(travels[0].oneWayPrice))
    },
    setImg(travel) {
      const travels = Array.isArray(travel) ? travel : [travel]
      let categoryID = travels[0].tariff.categoryID
      switch (categoryID) {
        case '54':
        case '55':
          return `${require(`@/assets/images/type_service/gaviota_diamente.jpeg`)}`
        case '10':
        case '23':
        case '44':
        case '42':
        case '60':
          return `${require(`@/assets/images/type_service/gaviota_preferencial.jpeg`)}`
        case '39':
        case '41':
        case '48':
        case '51':
        case '50':
          return `${require(`@/assets/images/type_service/premiun.jpeg`)}`
        case '58':
        case '37':
          return `${require(`@/assets/images/type_service/premiun_plus.jpeg`)}`
        case '40':
          return `${require(`@/assets/images/type_service/premiun_plus_extra.jpeg`)}`
        default:
          return `${require(`@/assets/images/type_service/premiun.jpeg`)}`
      }
    },
    setImgItem(travel) {
      const travels = Array.isArray(travel) ? travel : [travel]
      let categoryID = travels[0].tariff.categoryID
      switch (categoryID) {
        case '54':
        case '55':
          return `${require(`@/assets/images/type_service/gaviota_diamente.png`)}`
        case '10':
        case '23':
        case '44':
        case '42':
        case '60':
          return `${require(`@/assets/images/type_service/gaviota_preferencial.png`)}`
        case '39':
        case '41':
        case '48':
        case '51':
        case '50':
          return `${require(`@/assets/images/type_service/premiun.png`)}`
        case '58':
        case '37':
          return `${require(`@/assets/images/type_service/premiun_plus.png`)}`
        case '40':
          return `${require(`@/assets/images/type_service/premiun_plus_extra.png`)}`
        default:
          return `${require(`@/assets/images/type_service/premiun.png`)}`
      }
    },
    setTypeBus(value) {
      const travels = Array.isArray(value) ? value : [value]
      return travels[0].categoryName
    },
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
        {
          currency: 'COP',
        }).format(price)
    },
  },
}

</script>
