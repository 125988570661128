const optionsTypeDocumentWompi = [
  { value: 'CC', text: 'Cédula de Ciudadanía' },
  { value: 'CE', text: 'Cédula de Extranjería' },
  { value: 'NIT', text: 'Número de Identificación Tributaria' },
  { value: 'PP', text: 'Pasaporte' },
  { value: 'TI', text: 'Tarjeta de Identidad' },
  { value: 'DNI', text: 'Documento Nacional de Identidad' },
]

const optionsGender = [
  { value: null, text: 'Selecciona' },
  { value: 'Male', text: 'Hombre' },
  { value: 'Female', text: 'Mujer' },
  { value: 'Other', text: 'Otro' },
]

const optionsNacionalidad = [
  { value: null, text: 'Selecciona' },
  {"text": "Afganistán", "value": "AFGANA"},
  {"text": "Albania", "value": "ALBANESA"},
  {"text": "Alemania", "value": "ALEMANA"},
  {"text": "Andorra", "value": "ANDORRANA"},
  {"text": "Angola", "value": "ANGOLEÑA"},
  {"text": "Antigua y Barbuda", "value": "ANTIGUANA"},
  {"text": "Arabia Saudita", "value": "SAUDÍ"},
  {"text": "Argelia", "value": "ARGELINA"},
  {"text": "Argentina", "value": "ARGENTINA"},
  {"text": "Armenia", "value": "ARMENIA"},
  {"text": "Aruba", "value": "ARUBEÑA"},
  {"text": "Australia", "value": "AUSTRALIANA"},
  {"text": "Austria", "value": "AUSTRIACA"},
  {"text": "Azerbaiyán", "value": "AZERBAIYANA"},
  {"text": "Bahamas", "value": "BAHAMEÑA"},
  {"text": "Bangladés", "value": "BANGLADESÍ"},
  {"text": "Barbados", "value": "BARBADENSE"},
  {"text": "Baréin", "value": "BAREINÍ"},
  {"text": "Bélgica", "value": "BELGA"},
  {"text": "Belice", "value": "BELICEÑA"},
  {"text": "Benín", "value": "BENINÉSA"},
  {"text": "Bielorrusia", "value": "BIELORRUSA"},
  {"text": "Birmania", "value": "BIRMANA"},
  {"text": "Bolivia", "value": "BOLIVIANA"},
  {"text": "Bosnia y Herzegovina", "value": "BOSNIA"},
  {"text": "Botsuana", "value": "BOTSUANA"},
  {"text": "Brasil", "value": "BRASILEÑA"},
  {"text": "Brunéi", "value": "BRUNEANA"},
  {"text": "Bulgaria", "value": "BÚLGARA"},
  {"text": "Burkina Faso", "value": "BURKINÉS"},
  {"text": "Burundi", "value": "BURUNDÉSA"},
  {"text": "Bután", "value": "BUTANÉSA"},
  {"text": "Cabo Verde", "value": "CABOVERDIANA"},
  {"text": "Camboya", "value": "CAMBOYANA"},
  {"text": "Camerún", "value": "CAMERUNESA"},
  {"text": "Canadá", "value": "CANADIENSE"},
  {"text": "Catar", "value": "CATARÍ"},
  {"text": "Chad", "value": "CHADIANA"},
  {"text": "Chile", "value": "CHILENA"},
  {"text": "China", "value": "CHINA"},
  {"text": "Chipre", "value": "CHIPRIOTA"},
  {"text": "Ciudad del Vaticano", "value": "VATICANA"},
  {"text": "Colombia", "value": "COLOMBIANA"},
  {"text": "Comoras", "value": "COMORENSE"},
  {"text": "Corea del Norte", "value": "NORCOREANA"},
  {"text": "Corea del Sur", "value": "SURCOREANA"},
  {"text": "Costa de Marfil", "value": "MARFILEÑA"},
  {"text": "Costa Rica", "value": "COSTARRICENSE"},
  {"text": "Croacia", "value": "CROATA"},
  {"text": "Cuba", "value": "CUBANA"},
  {"text": "Dinamarca", "value": "DANÉSA"},
  {"text": "Dominica", "value": "DOMINIQUÉS"},
  {"text": "Ecuador", "value": "ECUATORIANA"},
  {"text": "Egipto", "value": "EGIPCIA"},
  {"text": "El Salvador", "value": "SALVADOREÑA"},
  {"text": "Emiratos Árabes Unidos", "value": "EMIRATÍ"},
  {"text": "Eritrea", "value": "ERITREA"},
  {"text": "Eslovaquia", "value": "ESLOVACA"},
  {"text": "Eslovenia", "value": "ESLOVENA"},
  {"text": "España", "value": "ESPAÑOLA"},
  {"text": "Estados Unidos", "value": "ESTADOUNIDENSE"},
  {"text": "Estonia", "value": "ESTONIA"},
  {"text": "Etiopía", "value": "ETÍOPE"},
  {"text": "Filipinas", "value": "FILIPINA"},
  {"text": "Finlandia", "value": "FINLANDÉSA"},
  {"text": "Fiyi", "value": "FIYIANA"},
  {"text": "Francia", "value": "FRANCÉSA"},
  {"text": "Gabón", "value": "GABONÉSA"},
  {"text": "Gambia", "value": "GAMBIANA"},
  {"text": "Georgia", "value": "GEORGIANA"},
  {"text": "Gibraltar", "value": "GIBRALTAREÑA"},
  {"text": "Ghana", "value": "GHANÉSA"},
  {"text": "Granada", "value": "GRANADINA"},
  {"text": "Grecia", "value": "GRIEGA"},
  {"text": "Groenlandia", "value": "GROENLANDÉSA"},
  {"text": "Guatemala", "value": "GUATEMALTECA"},
  {"text": "Guinea ecuatorial", "value": "ECUATOGUINEANA"},
  {"text": "Guinea", "value": "GUINEANA"},
  {"text": "Guinea-Bisáu", "value": "GUINEANA"},
  {"text": "Guyana", "value": "GUYANESA"},
  {"text": "Haití", "value": "HAITIANA"},
  {"text": "Honduras", "value": "HONDUREÑA"},
  {"text": "Hungría", "value": "HÚNGARA"},
  {"text": "India", "value": "HINDÚ"},
  {"text": "Indonesia", "value": "INDONESIA"},
  {"text": "Irak", "value": "IRAQUÍ"},
  {"text": "Irán", "value": "IRANÍ"},
  {"text": "Irlanda", "value": "IRLANDÉSA"},
  {"text": "Islandia", "value": "ISLANDÉSA"},
  {"text": "Islas Cook", "value": "COOKIANA"},
  {"text": "Islas Marshall", "value": "MARSHALÉSA"},
  {"text": "Islas Salomón", "value": "SALOMONENSE"},
  {"text": "Israel", "value": "ISRAELÍ"},
  {"text": "Italia", "value": "ITALIANA"},
  {"text": "Jamaica", "value": "JAMAIQUINA"},
  {"text": "Japón", "value": "JAPONÉSA"},
  {"text": "Jordania", "value": "JORDANA"},
  {"text": "Kazajistán", "value": "KAZAJA"},
  {"text": "Kenia", "value": "KENIATA"},
  {"text": "Kirguistán", "value": "KIRGUISA"},
  {"text": "Kiribati", "value": "KIRIBATIANA"},
  {"text": "Kuwait", "value": "KUWAITÍ"},
  {"text": "Laos", "value": "LAOSIANA"},
  {"text": "Lesoto", "value": "LESOTENSE"},
  {"text": "Letonia", "value": "LETÓNA"},
  {"text": "Líbano", "value": "LIBANÉSA"},
  {"text": "Liberia", "value": "LIBERIANA"},
  {"text": "Libia", "value": "LIBIA"},
  {"text": "Liechtenstein", "value": "LIECHTENSTEINIANA"},
  {"text": "Lituania", "value": "LITUANA"},
  {"text": "Luxemburgo", "value": "LUXEMBURGUÉSA"},
  {"text": "Madagascar", "value": "MALGACHE"},
  {"text": "Malasia", "value": "MALASIA"},
  {"text": "Malaui", "value": "MALAUÍ"},
  {"text": "Maldivas", "value": "MALDIVA"},
  {"text": "Malí", "value": "MALIENSE"},
  {"text": "Malta", "value": "MALTÉSA"},
  {"text": "Marruecos", "value": "MARROQUÍ"},
  {"text": "Martinica", "value": "MARTINIQUÉS"},
  {"text": "Mauricio", "value": "MAURICIANA"},
  {"text": "Mauritania", "value": "MAURITANA"},
  {"text": "México", "value": "MEXICANA"},
  {"text": "Micronesia", "value": "MICRONESIA"},
  {"text": "Moldavia", "value": "MOLDAVA"},
  {"text": "Mónaco", "value": "MONEGASCA"},
  {"text": "Mongolia", "value": "MONGOLA"},
  {"text": "Montenegro", "value": "MONTENEGRINA"},
  {"text": "Mozambique", "value": "MOZAMBIQUEÑA"},
  {"text": "Namibia", "value": "NAMIBIA"},
  {"text": "Nauru", "value": "NAURUANA"},
  {"text": "Nepal", "value": "NEPALÍ"},
  {"text": "Nicaragua", "value": "NICARAGÜENSE"},
  {"text": "Níger", "value": "NIGERINA"},
  {"text": "Nigeria", "value": "NIGERIANA"},
  {"text": "Noruega", "value": "NORUEGA"},
  {"text": "Nueva Zelanda", "value": "NEOZELANDÉSA"},
  {"text": "Omán", "value": "OMANÍ"},
  {"text": "Países Bajos", "value": "NEERLANDÉSA"},
  {"text": "Pakistán", "value": "PAKISTANÍ"},
  {"text": "Palaos", "value": "PALAUANA"},
  {"text": "Palestina", "value": "PALESTINA"},
  {"text": "Panamá", "value": "PANAMEÑA"},
  {"text": "Papúa Nueva Guinea", "value": "PAPÚ"},
  {"text": "Paraguay", "value": "PARAGUAYA"},
  {"text": "Perú", "value": "PERUANA"},
  {"text": "Polonia", "value": "POLACA"},
  {"text": "Portugal", "value": "PORTUGUÉSA"},
  {"text": "Puerto Rico", "value": "PUERTORRIQUEÑA"},
  {"text": "Reino Unido", "value": "BRITÁNICA"},
  {"text": "República Centroafricana", "value": "CENTROAFRICANA"},
  {"text": "República Checa", "value": "CHECA"},
  {"text": "República de Macedonia", "value": "MACEDONIA"},
  {"text": "República del Congo", "value": "CONGOLEÑA"},
  {"text": "República Democrática del Congo", "value": "CONGOLEÑA"},
  {"text": "República Dominicana", "value": "DOMINICANA"},
  {"text": "República Sudafricana", "value": "SUDAFRICANA"},
  {"text": "Ruanda", "value": "RUANDÉSA"},
  {"text": "Rumanía", "value": "RUMANA"},
  {"text": "Rusia", "value": "RUSA"},
  {"text": "Samoa", "value": "SAMOANA"},
  {"text": "San Cristóbal y Nieves", "value": "CRISTOBALEÑA"},
  {"text": "San Marino", "value": "SANMARINENSE"},
  {"text": "San Vicente y las Granadinas", "value": "SANVICENTINA"},
  {"text": "Santa Lucía", "value": "SANTALUCENSE"},
  {"text": "Santo Tomé y Príncipe", "value": "SANTOTOMENSE"},
  {"text": "Senegal", "value": "SENEGALÉSA"},
  {"text": "Serbia", "value": "SERBIA"},
  {"text": "Seychelles", "value": "SEYCHELLENSE"},
  {"text": "Sierra Leona", "value": "SIERRALEONÉSA"},
  {"text": "Singapur", "value": "SINGAPURENSE"},
  {"text": "Siria", "value": "SIRIA"},
  {"text": "Somalia", "value": "SOMALÍ"},
  {"text": "Sri Lanka", "value": "CEILANÉSA"},
  {"text": "Suazilandia", "value": "SUAZI"},
  {"text": "Sudán del Sur", "value": "SURSUDANÉSA"},
  {"text": "Sudán", "value": "SUDANÉSA"},
  {"text": "Suecia", "value": "SUECA"},
  {"text": "Suiza", "value": "SUIZA"},
  {"text": "Surinam", "value": "SURINAMESA"},
  {"text": "Tailandia", "value": "TAILANDÉSA"},
  {"text": "Tanzania", "value": "TANZANA"},
  {"text": "Tayikistán", "value": "TAYIKA"},
  {"text": "Timor Oriental", "value": "TIMORENSE"},
  {"text": "Togo", "value": "TOGOLÉSA"},
  {"text": "Tonga", "value": "TONGANA"},
  {"text": "Trinidad y Tobago", "value": "TRINITENSE"},
  {"text": "Túnez", "value": "TUNECINA"},
  {"text": "Turkmenistán", "value": "TURCOMANA"},
  {"text": "Turquía", "value": "TURCA"},
  {"text": "Tuvalu", "value": "TUVALUANA"},
  {"text": "Ucrania", "value": "UCRANIANA"},
  {"text": "Uganda", "value": "UGANDÉSA"},
  {"text": "Uruguay", "value": "URUGUAYA"},
  {"text": "Uzbekistán", "value": "UZBEKA"},
  {"text": "Vanuatu", "value": "VANUATUENSE"},
  {"text": "Venezuela", "value": "VENEZOLANA"},
  {"text": "Vietnam", "value": "VIETNAMITA"},
  {"text": "Yemen", "value": "YEMENÍ"},
  {"text": "Yibuti", "value": "YIBUTIANA"},
  {"text": "Zambia", "value": "ZAMBIANA"},
  {"text": "Zimbabue", "value": "ZIMBABUENSE"}
]

const formatJson = data => Object.values(data).flatMap(({
  company_id, name, photo, travel,
}) => {
  const travels = Array.isArray(travel) ? travel : [travel]
  return travels.map(({
    '@attributes': {
      Id,
      FechaPartida,
      FechaArribo,
      TerminalOrigenNombre,
      TerminalDestinoNombre,
      TerminalOrigenID,
      TerminalDestinoID,
      LocalidadDestinoNombre,
      LocalidadOrigenNombre,
    },
    tarifa,
  }) => ({
    idViaje: Id,
    company_id,
    empresa: name,
    photo,
    FechaPartida,
    FechaArribo,
    TerminalOrigenNombre,
    TerminalDestinoNombre,
    TerminalOrigenID,
    TerminalDestinoID,
    LocalidadDestinoNombre,
    LocalidadOrigenNombre,
    tarifa,
  }))
})

const formatJsonForm = data => Object.values(data).flatMap(({
  company_id, name, photo, transaction_fee, policy, travel,
}) => {
  const travels = Array.isArray(travel) ? travel : [travel]
  return travels.map(({
    '@attributes': {
      Id,
      FechaPartida,
      FechaArribo,
      TerminalOrigenNombre,
      TerminalDestinoNombre,
      TerminalOrigenID,
      TerminalDestinoID,
      LocalidadDestinoNombre,
      LocalidadOrigenNombre,
    },
    tarifa,
  }) => ({
    idViaje: Id,
    company_id,
    empresa: name,
    photo,
    FechaPartida,
    FechaArribo,
    TerminalOrigenNombre,
    TerminalDestinoNombre,
    tarifa,
    policy,
    transaction_fee,
    TerminalOrigenID,
    TerminalDestinoID,
    LocalidadDestinoNombre,
    LocalidadOrigenNombre,
  }))
})

export {
  optionsTypeDocumentWompi, optionsGender, optionsNacionalidad, formatJson, formatJsonForm,
}
