<template>
  <div>
    <template
      v-for="(travel, key) in travels"
    >
      <vue-card-ticket
        :travel="travel"
        @travel-butacas="setButacas"
      >
        <template v-slot:img-logo>
          <b-img
            width="120"
            :src="setImg(travel)"
          />
        </template>
      </vue-card-ticket>
    </template>
  </div>
</template>

<script>
import {
  BImg,
} from 'bootstrap-vue'
import CardTicket from '@/components/SearchTicket/Ticket/CardTicket.vue'

export default {
  components: {
    BImg,
    'vue-card-ticket': CardTicket,
  },
  props: {
    travels: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setImg(travel) {
      if (travel.typeIntegration == 'sisorg') {
        return `${process.env.VUE_APP_URL_STORAGE}${travel.companyPhoto}`
      } else if (travel.typeIntegration == 'brasilia') {
        switch (travel.companyCodeLine) {
          case 'EC':
            return require('@/assets/images/company/1.jpeg')
          case 'UN':
            return require('@/assets/images/company/2.jpeg')
          case 'EA':
            return require('@/assets/images/company/3.jpeg')
          default:
            return `${process.env.VUE_APP_URL_STORAGE}${travel.companyPhoto}`
        }
      }
    },
    setButacas(travel) {
      this.$emit('travel-butacas', travel)
    },
  },
}

</script>
