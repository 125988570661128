<template>
  <div>
    <h4 class="font-lg font-weight-bolder">Empresas</h4>
    <b-form-group>
      <b-form-checkbox
        v-for="option in optionsCompany"
        :key="option.text"
        v-model="selectedCompany"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
    <hr>
    <h4  class="font-lg font-weight-bolder">Tipo de Servicio</h4>
    <b-form-group>
      <b-form-checkbox
        v-for="option in optionsTypebus"
        :key="option.text"
        v-model="selectedTypeBus"
        :value="option.value"
        name="flavour-3a"
      >
        {{ option.text }}
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    travels: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedCompany: [],
      selectedTypeBus: [],
      optionsCompany: [],
      optionsTypebus: [],
    }
  },
  watch: {
    selectedCompany(value) {
      this.$emit('companies-filter', value)
    },
    selectedTypeBus(value) {
      this.$emit('type-bus-filter', value)
    },
  },
  async mounted() {
    this.optionsCompany = await this.companiesValues(this.travels)
    this.optionsTypebus = await this.typeBusValues(this.travels)
  },
  methods: {
    companiesValues(data) {
      // eslint-disable-next-line
      const companies = data.map(row => ({
        text: row.companyName, // Extract company name from companyName field
        value: row.companyName, // Set the same value
      }));

      // Remove duplicates using a Set on the value
      const uniqueCompanies = Array.from(new Set(companies.map(company => company.value)))
        .map(value => companies.find(company => company.value === value));

      return uniqueCompanies;
    },
    typeBusValues(value) {
      const typeBus = []
      // eslint-disable-next-line
      for (const row in value) { // eslint-disable-next-line
        const tariff = Array.isArray(value[row].tariff) ? value[row].tariff : [value[row].tariff];
        const index = typeBus.findIndex(object => object.value === tariff[0].categoryName) // eslint-disable-next-line
        if (index < 0) {
          typeBus.push({
            text: tariff[0].categoryName,
            value: tariff[0].categoryName,
          })
        }
      }
      return typeBus
    },
  },
}

</script>
