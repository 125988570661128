<template>
  <b-container>
    <div class="text-center mb-2">
      <h3 class="text-primary">¡Prepárate para el viaje! Elige tu asiento en el autobús.</h3>
    </div>
    <b-row>
      <b-col cols="12" sm="5">
        <div class="bus-style-front"></div>
        <div class="bus-layout">
          <table>
            <tbody>
              <tr v-for="(row, rowIndex) in seatRows" :key="rowIndex">
                <!-- Columna izquierda -->
                <td v-for="(seat, cIndex) in row.slice(0, 2)" :key="seat.numero">
                  <svg
                    @click="onSeatSelected(seat)"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
                    transform="rotate(90)"
                    :class="getSeatClass(seat)"
                  >
                  <path :class="getSeatClass(seat)"
                    d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                  <path :class="getSeatClass(seat)"
                    d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                  <path :class="getSeatClass(seat)"
                    d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                  <path :class="getSeatClass(seat)"
                    d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                  <path :class="getSeatClass(seat)"
                    d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
                    <text
                      data-v-40dbf09e=""
                      x="48%"
                      y="55%"
                      fill="black"
                      text-anchor="middle"s
                      font-size="45px"
                      transform="rotate(-90, 50, 50)"
                    > {{ seat.numero }} </text>
                  </svg>
                </td>
                <!-- Pasillo -->
                <td>&nbsp;</td>
                <!-- Columna derecha -->
                <td v-for="(seat, cIndex) in row.slice(2, 4)" :key="seat.numero">
                  <svg
                    @click="onSeatSelected(seat)"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"
                    transform="rotate(90)"
                  >
                  <path :class="getSeatClass(seat)"
                    d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                  <path :class="getSeatClass(seat)"
                    d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                  <path :class="getSeatClass(seat)"
                    d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                  <path :class="getSeatClass(seat)"
                    d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                  <path :class="getSeatClass(seat)"
                    d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
                    <text
                      data-v-40dbf09e=""
                      x="48%"
                      y="55%"
                      fill="black"
                      text-anchor="middle"
                      font-size="45px"
                      transform="rotate(-90, 50, 50)"
                    > {{ seat.numero }} </text>
                  </svg>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bus-style-back"></div>
      </b-col>
      <b-col cols="12" sm="7">
        <b-list-group>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-libre"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-libre"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-libre"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-libre"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-libre"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla disponible</span>
          </b-list-group-item>

          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-selected"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-selected"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-selected"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-selected"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-selected"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla seleccionada</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-vendida"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-vendida"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-vendida"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-vendida"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-vendida"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla ocupada</span>
          </b-list-group-item>
          <b-list-group-item class="d-flex">
            <span class="mr-1">
              <div class="float-left" style="width: 20px;">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                transform="rotate(90)"
              >
                <path class="cls-bloqueo"
                  d="M36,17.3H80.4a8.88,8.88,0,0,1,6.72-7.25A5.77,5.77,0,0,0,81.57,6H36a5.72,5.72,0,0,0-5.76,5.66A5.71,5.71,0,0,0,36,17.3Z" />
                <path class="cls-bloqueo"
                  d="M80.29,82.79H36A5.66,5.66,0,1,0,36,94.1H81.47a6.13,6.13,0,0,0,5.44-3.41A8.77,8.77,0,0,1,80.29,82.79Z" />
                <path class="cls-bloqueo"
                  d="M80.08,79.7V20.5H35.92A8.85,8.85,0,0,1,27.17,13h-18a4,4,0,0,0-4.06,4V82.79a4,4,0,0,0,4.06,3.95H27.28a8.65,8.65,0,0,1,8.75-7Z" />
                <path class="cls-bloqueo"
                  d="M89.15,12.93a5.71,5.71,0,0,0-5.76,5.65V82.15a5.76,5.76,0,0,0,11.52,0V18.58A5.71,5.71,0,0,0,89.15,12.93Z" />
                <path class="cls-bloqueo"
                  d="M90.21,9.94a8.93,8.93,0,0,0-8.74-7H36a8.94,8.94,0,0,0-8.75,6.93H9.15A7.22,7.22,0,0,0,2,17V82.79a7.06,7.06,0,0,0,7.15,7h18a8.85,8.85,0,0,0,8.75,7.26H81.47A8.91,8.91,0,0,0,90,90.9a8.81,8.81,0,0,0,8-8.75V18.58A8.84,8.84,0,0,0,90.21,9.94ZM36,6H81.57a5.77,5.77,0,0,1,5.55,4.06A8.88,8.88,0,0,0,80.4,17.3H36a5.71,5.71,0,0,1-5.76-5.65A5.72,5.72,0,0,1,36,6ZM27.28,86.74H9.15a4,4,0,0,1-4.06-3.95V17a4,4,0,0,1,4.06-4h18a8.85,8.85,0,0,0,8.75,7.47H80.08V79.7H36A8.65,8.65,0,0,0,27.28,86.74ZM81.47,94.1H36a5.66,5.66,0,1,1,0-11.31H80.29a8.77,8.77,0,0,0,6.62,7.9A6.13,6.13,0,0,1,81.47,94.1ZM94.91,82.15a5.76,5.76,0,0,1-11.52,0V18.58a5.76,5.76,0,0,1,11.52,0Z" />
              </svg>
            </div>
            </span>
            <span class="text-primary">Silla bloqueada</span>
          </b-list-group-item>
        </b-list-group>
        <hr>
        <b-list-group flush>
            <b-list-group-item class="d-flex justify-content-between align-items-center">
              <span>Tipo de Servicio</span>
              <b-badge
                variant="primary"
                pill
                class="badge-round"
              >
                {{ tipobus }}
              </b-badge>
            </b-list-group-item>
          </b-list-group>
        <hr>
        <h6 class="mt-2 text-primary">Sillas seleccionadas</h6>
        <b-alert
          v-if="selectedSeat.length == 0"
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25"> ¡Parece que no has seleccionado ninguna silla aún! Por favor, elige tus sillas para continuar.</span>
          </div>
        </b-alert>
        <b-list-group flush>
          <b-list-group-item
            v-for="(seat, key) in selectedSeat"
            :key="key"
            class="d-flex justify-content-between align-items-center"
          >
            <span><b>Silla</b> {{seat.numero}}</span>
            <span>${{ setPricing(seat) }}</span>
          </b-list-group-item>
          <b-list-group-item
            v-if="selectedSeat.length > 0"
            class="d-flex justify-content-between align-items-center"
          >
            <span class="font-weight-bolder">Subtotal</span>
            <span>${{ setPricingSubTotal(selectedSeat) }}</span>
          </b-list-group-item>
        </b-list-group>
        <b-alert
          v-if="selectedSeat.length >= 6"
          show
          variant="primary"
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25"> Has alcanzado el tope máximo de sillas permitidas por este operador en una sola transacción.</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
</b-container>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody, BLink, BRow, BCol,
  BContainer, BListGroup, BListGroupItem, BBadge, BAlert
} from 'bootstrap-vue'
export default {
  components: {
    BCard,
    BCardTitle,
    BLink,
    BRow,
    BCol,
    BCardBody,
    BCardHeader,
    BContainer,
    BListGroup,
    BListGroupItem,
    BBadge,
    BAlert
  },
  props: {
    seats: {
      type: Array,
      required: false,
      default: []
    },
    tipobus: {
      type: String,
      required: false,
      default: ""
    },
    tariff: {
      type: Object,
      required: false,
      default: ""
    },
  },
  data() {
    return {
      seatRows: [],
      selectedSeat: [],
    };
  },
  mounted() {
    this.organizeSeats();
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('de-DE',
        {
          currency: 'COP',
        }).format(price)
    },
    organizeSeats() {
      let seats = this.seats;
      const rows = [];
      // const uniqueStates = [...new Set(seats.map(item => item.estado))];

      // // Display the unique states
      // console.log(uniqueStates);
      for (let i = 0; i < seats.length; i += 4) {
        const leftSeats = seats.slice(i, i + 2);
        const rightSeats = seats.slice(i + 2, i + 4).reverse();
        rows.push(leftSeats.concat(rightSeats));
      }
      this.seatRows = rows;
    },
    getSeatClass(seat) {
      if (seat != null) {
        let position = this.selectedSeat.findIndex(row => row.ubicacion == seat.ubicacion)
          if(position === -1) {
            switch (seat.estado) {
              case 'L':
                return 'cls-libre';
              case 'V':
                return 'cls-vendida';
              case 'R':
                return 'cls-reservada';
              case 'O':
                return 'cls-bloqueo';
              case '3':
                return 'cls-preferencial';
              case '4':
                return 'cls-discapacitados';
              default:
                return 'cls-libre';
            }
        } else {
          return 'cls-selected';
        }
      }
    },
    setPricing(value) {
      const pricingSilla = this.tariff;
      let price = pricingSilla.oneWayPrice;
      let discountApplied = price * value.descuento;
      let finalPrice = price - discountApplied;
      return this.formatPrice(finalPrice)
    },
    setPricingSubTotal(value) {
      let subTotal = 0
      const pricingSilla = this.tariff;
      value.forEach(row => {
        subTotal = subTotal + parseFloat(pricingSilla.oneWayPrice)
      })
      return this.formatPrice(subTotal)
    },

    setPricingTotal(value) {
      let subTotal = 0
      const pricingSilla = this.tariff;
      value.forEach(row => {
        subTotal = subTotal + parseFloat(pricingSilla.oneWayPrice)
      })
      return subTotal
    },
    async onSeatSelected(seat) {
      let selectIndex = this.selectedSeat.findIndex(row => row.ubicacion == seat.ubicacion)
      if (seat.estado === "L" && this.selectedSeat.length <= 5) {
        if (selectIndex === -1) {
          this.selectedSeat.push(seat)
        } else {
          this.selectedSeat.splice(selectIndex, 1)
        }
      } else if(selectIndex != -1) {
        this.selectedSeat.splice(selectIndex, 1)
      }
      // se debe agregar el precio base y el valor con descuento, (Listo)
      // los tiquetes se graba el precio normal no la sumatoria de ellos, (Listo)
      // adicional agregar en el formulario de clientes la nacionalidad, (Listo)
      // y ya el guardar. (Listo)
      // Agregar campo en la db para guardar la tarifa base (Listo)
      // Realizar pruebas de ventas de tiquetes y aprobación del mismo (Listo)
      // Agregar la nacionalidad y enviarla con el gentilicio Colombiano (Listo)
      // Arreglar el formulario del tipo de documento para que soporte las dos integraciones (Listo)

      // soportar por el tipo de servicio las imagenes (Listo)
      // Integrar wompi
      // Integrar las comisiones de wompi de acuerdos a las formulas del excel
      // Limitar caracteres en los clientes
      this.$emit('total-seat', {
        selected: this.selectedSeat,
        total: this.selectedSeat.length,
        pricingTotal: this.setPricingTotal(this.selectedSeat),
      })
    },
  },
};
</script>

<style>
.bus-layout {
  margin: 20px;
  justify-items: center;
}

.bus-layout td {
  width: 50px;
  text-align: center;
  vertical-align: middle;
  padding-left: 1rem;
}

.occupied-seat {
  background-color: #ffccbc;
}
</style>